import { gql } from '@apollo/client';

import { City, Country } from 'src/global/models';

export type Maybe<T> = T | null;

export type HierarchicalLocation = {
  __typename?: 'HierarchicalLocation';
  administrativeLevelName: string;
  children?: Maybe<Array<HierarchicalLocation>>;
  CountryCode?: Maybe<string>;
  formattedName: string;
  id: string;
  latitude: number;
  level: number;
  longitude: number;
  name: string;
  parent?: Maybe<HierarchicalLocation>;
  parents?: Maybe<Array<HierarchicalLocation>>;
  slug?: Maybe<string>;
};

export type JobLocationPreferenceType = {
  __typename?: 'JobLocationPreferenceType';
  City: City;
  CityId: number;
  Country: Country;
  CountryCode: string;
  createdAt: string;
  HierarchicalLocation?: Maybe<HierarchicalLocation>;
  id: string;
  LocationId?: Maybe<string>;
  needsVisa?: boolean;
  updatedAt: string;
  UserId: string;
};

export type HierarchicalJobLocationPreferenceInput = {
  LocationId: string;
  needsVisa?: Maybe<boolean>;
};

export type MutationUpdateJobLocationPreferencesArgs = {
  addHierarchicalJobLocationPreferencesInput: Array<HierarchicalJobLocationPreferenceInput>;
  deleteJobLocationPreferencesIds: Array<string>;
};

export type QuerySearchHierarchicalLocationsArgs = {
  countryCode?: Maybe<string>;
  levels?: Maybe<Array<number>>;
  limit?: Maybe<number>;
  searchTerm: string;
  withActiveJobsOnly?: Maybe<boolean>;
};

export type SuggestedHierarchicalJobLocationsArgs = {
  countryCode: string;
};

export type SearchHierarchicalLocationData = {
  searchHierarchicalLocations: Array<HierarchicalLocation>;
};

export type HierarchicalJobLocationPreferencesData = {
  jobLocationPreferences: Array<JobLocationPreferenceType>;
};

export type HierarchicalLocationByIdData = {
  location: Maybe<HierarchicalLocation>;
};

export type UpdateJobLocationPreferencesData = {
  updateJobLocationPreferences: Array<JobLocationPreferenceType>;
};

export type SuggestedHierarchicalJobLocationsData = {
  suggestedJobLocations: Array<HierarchicalLocation>;
};

const HierarchicalLocationFields = gql`
  fragment HierarchicalLocationFragment on HierarchicalLocation {
    id
    name
    administrativeLevelName
    formattedName
    CountryCode: countryCode
    level
    parents {
      level
    }
  }
`;

export const JobLocationPreferenceFields = gql`
  fragment JobLocationPreferenceFields on JobLocationPreferenceType {
    id
    needsVisa
    CountryCode
    CityId
    UserId
    createdAt
    updatedAt
    LocationId
    City {
      id
      name
      CountryCode
    }
    Country {
      code
      name
      demonym
      currency
      languages {
        key
        value
      }
      callingCode
    }
    HierarchicalLocation {
      id
      latitude
      longitude
      CountryCode: countryCode
      name
      administrativeLevelName
      formattedName
      parents {
        id
        name
        level
        administrativeLevelName
        formattedName
      }
    }
  }
`;

export const searchHierarchicalLocation = gql`
  query searchHierarchicalLocations(
    $searchTerm: String!
    $levels: [PositiveInt!]
    $limit: PositiveInt
    $withActiveJobsOnly: Boolean
    $countryCode: String
  ) {
    searchHierarchicalLocations(
      searchTerm: $searchTerm
      levels: $levels
      limit: $limit
      withActiveJobsOnly: $withActiveJobsOnly
      countryCode: $countryCode
    ) {
      id
      CountryCode: countryCode
      name
      formattedName
      level
      parents {
        id
        name
        formattedName
        level
        CountryCode: countryCode
        parents {
          level
        }
      }
    }
  }
`;

export const hierarchicalJobLocationPreference = gql`
  query jobLocationPreferences {
    jobLocationPreferences {
      ...JobLocationPreferenceFields
    }
  }
  ${JobLocationPreferenceFields}
`;

export const getHierarchicalLocationById = gql`
  query getHierarchicalLocationById($id: String!) {
    location(id: $id) {
      id
      latitude
      longitude
      CountryCode: countryCode
      name
      administrativeLevelName
      formattedName
      parents {
        id
        name
        level
        administrativeLevelName
        formattedName
      }
    }
  }
`;

export const updateJobLocationPreferences = gql`
  mutation updateJobLocationPreferences(
    $addHierarchicalJobLocationPreferencesInput: [HierarchicalJobLocationPreferenceInput!]!
    $deleteJobLocationPreferencesIds: [UUID!]!
  ) {
    updateJobLocationPreferences(
      addHierarchicalJobLocationPreferencesInput: $addHierarchicalJobLocationPreferencesInput
      deleteJobLocationPreferencesIds: $deleteJobLocationPreferencesIds
    ) {
      ...JobLocationPreferenceFields
    }
  }
  ${JobLocationPreferenceFields}
`;

export const suggestedJobLocations = gql`
  query suggestedJobLocations($countryCode: String!) {
    suggestedJobLocations(countryCode: $countryCode) {
      ...HierarchicalLocationFragment
      parents {
        ...HierarchicalLocationFragment
      }
      children {
        ...HierarchicalLocationFragment
      }
    }
  }
  ${HierarchicalLocationFields}
`;
