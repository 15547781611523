import { gql } from '@apollo/client';

import { City, Country } from 'src/global/models';

export interface LocationResponse {
  name?: string;
  citySubDivision?: { name: string; id: number };
  city?: City;
  country: Country;
}

export type QuerySearchLocationsArgs = {
  limit?: number;
  searchTerm: string;
  CountryCode?: string;
  withActiveJobsOnly?: boolean;
};

export type QueryGetLocationSuggestionsData = {
  searchLocations: Array<LocationResponse>;
};

export const queryGetJobKeywordSuggestions = gql`
  query getJobTitleSuggestions(
    $keyword: String!
    $countryCode: String
    $limit: Int
  ) {
    getJobTitleSuggestions(
      keyword: $keyword
      countryCode: $countryCode
      limit: $limit
    )
  }
`;

export const queryGetJobKeywordSuggestionsV2 = gql`
  query getJobTitleSuggestions(
    $keyword: String!
    $countryCode: String
    $limit: Int
  ) {
    getJobTitleSuggestions(
      keyword: $keyword
      countryCode: $countryCode
      limit: $limit
    ) {
      jobTitles
      count
      expInfo
    }
  }
`;

export const queryGetLocationSuggestions = gql`
  query searchLocations(
    $searchTerm: String!
    $limit: PositiveInt
    $CountryCode: String
  ) {
    searchLocations(
      searchTerm: $searchTerm
      limit: $limit
      CountryCode: $CountryCode
    ) {
      name
      country {
        name
        code
      }
      city {
        name
        id
      }
      citySubDivision {
        name
        id
      }
    }
  }
`;

export const getCitiesWithActiveJobs = gql`
  query getCitiesWithActiveJobs(
    $countryCode: String
    $pageSize: Int
    $currentPage: Int
  ) {
    getCitiesWithActiveJobs(
      countryCode: $countryCode
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      cities {
        City {
          id
          name
          CountryCode
          country {
            name
            code
          }
        }
      }
    }
  }
`;

export const getCountryListForJobSearch = gql`
  query getCountryListForJobSearch($countryCode: String!) {
    getCountryListForJobSearch(countryCode: $countryCode) {
      callingCode
      currency
      demonym
      code
      id
      name
    }
  }
`;
