import { createContext } from 'react';

import { CountryCodes } from 'src/common/enums';
import { SEOSlugQuery } from 'src/modules/Opportunities/pages/Explore/types';
import { SlugTypes } from 'src/modules/Opportunities/utils/slugUrlParams';

export const SEOSlugUrlParams = createContext<{
  type?: SlugTypes;
  slugQuery?: SEOSlugQuery;
  isSlugUrl?: boolean;
  countryCode?: CountryCodes;
  freeQueryKeyword?: string;
  customSlugPageKeyword?: string;
}>({});
