import { useContext } from 'react';
import { identity, isNil, omit, omitBy, pick, pickBy, union } from 'lodash';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { LocalStorageKeys } from 'src/common/constants';
import {
  CountryCodes,
  defaultCityProvinceAll,
  defaultCityProvinceInVN,
  defaultLocationIdInVN,
} from 'src/common/enums';
import { ROUTES } from 'src/common/routes';
import { hasLocalStorage } from 'src/common/utils/hasLocalStorage';
import { NewSearchLocation } from 'src/global/models/NewSearchLocation';

import { getBasename } from '../../../../../ClientConfig/Selectors';
import { useLocationIdFromSlug } from '../../../../hooks/useLocationIdFromSlug';
import { SearchRouterQuery, SearchRouterQueryKey } from '../../types';
import { SEOSlugUrlParams } from '../FilterSection/context';
import { LocationQuery } from './utils';

const uniqueJobSearch = (
  arr: SearchRouterQuery[],
  props: (keyof SearchRouterQuery)[] = []
) => [
  ...new Map(
    arr.map((entry: SearchRouterQuery) => [
      props.map((k) => entry[k]).join('|'),
      entry,
    ])
  ).values(),
];

const storeSearchInLocalStorage = (
  searchCondition: SearchRouterQuery,
  updatePastJobSearches: (pastSearches: SearchRouterQuery[]) => void
) => {
  if (!hasLocalStorage()) {
    return;
  }

  let pastJobSearchesStored = JSON.parse(
    localStorage.getItem(
      LocalStorageKeys.pastJobSearchConditionsInExploreTab
    ) || '[]'
  );

  pastJobSearchesStored = union(
    [pickBy(searchCondition, identity)],
    pastJobSearchesStored
  );
  pastJobSearchesStored = uniqueJobSearch(pastJobSearchesStored, [
    SearchRouterQueryKey.Keyword,
    SearchRouterQueryKey.Location,
  ]);
  if (pastJobSearchesStored.length >= 3) {
    pastJobSearchesStored = pastJobSearchesStored.slice(0, 3);
  }

  if (updatePastJobSearches) {
    updatePastJobSearches(pastJobSearchesStored);
  }
};

export function useGenerateLocationQuery(
  locationResponse: NewSearchLocation[],
  searchLocation: NewSearchLocation | null,
  selectedCountry: CountryCodes
) {
  const router = useRouter();
  const { slugQuery } = useContext(SEOSlugUrlParams);
  const { locationId: _locationId } = slugQuery || {};

  const basename = useSelector(getBasename);
  const pathname = basename
    ? router.asPath.replace(basename, '')
    : router.asPath;
  const locationId = useLocationIdFromSlug(pathname, _locationId);

  const location = pick(
    router.query,
    omit(Object.values(SearchRouterQueryKey), [SearchRouterQueryKey.Keyword])
  ) as LocationQuery;

  // If new location is entered in the location search field.
  const locationHasChanged =
    location[SearchRouterQueryKey.Location] !== searchLocation?.label;
  const countryHasChanged =
    location[SearchRouterQueryKey.Country] !== selectedCountry;

  if (locationHasChanged || countryHasChanged) {
    const response = locationResponse.find(
      (res) => res.label === searchLocation?.label
    );

    if (pathname.match(/^\/job-location\/([^/]+)/)) {
      return {
        [SearchRouterQueryKey.Country]: selectedCountry,
        [SearchRouterQueryKey.LocationId]: locationId,
        [SearchRouterQueryKey.Location]: searchLocation.label,
        ...(searchLocation.hierarchicalLocation && {
          [SearchRouterQueryKey.LowestLocationlevel]:
            searchLocation.hierarchicalLocation.level.toString(),
        }),
      };
    } else if (searchLocation?.label === defaultCityProvinceAll) {
      /** if all cities/provinces */
      return {
        [SearchRouterQueryKey.Country]: selectedCountry,
        [SearchRouterQueryKey.Location]: defaultCityProvinceAll,
        [SearchRouterQueryKey.LowestLocationlevel]: '1',
      };
    } else if (response?.defaultLocation) {
      return {
        [SearchRouterQueryKey.Country]: response?.defaultLocation.CountryCode,
        [SearchRouterQueryKey.Location]: response?.label,
        ...(response?.defaultLocation?.id && {
          [SearchRouterQueryKey.LocationId]:
            response.defaultLocation.id.toString(),
        }),
        ...(response?.hierarchicalLocation && {
          [SearchRouterQueryKey.LowestLocationlevel]:
            response.hierarchicalLocation.level.toString(),
        }),
      };
    } else if (response?.isHierarchicalLocation) {
      return {
        [SearchRouterQueryKey.Country]:
          response?.hierarchicalLocation.CountryCode,
        [SearchRouterQueryKey.LocationId]: response?.hierarchicalLocation.id,
        [SearchRouterQueryKey.Location]: response?.label,
        ...(response?.hierarchicalLocation && {
          [SearchRouterQueryKey.LowestLocationlevel]:
            response.hierarchicalLocation.level.toString(),
        }),
      };
    } else if (response?.legacySearchLocation) {
      return {
        [SearchRouterQueryKey.Country]:
          response?.legacySearchLocation.links?.country?.code,
        // @ts-ignore Optional chaining in city id because, when only country is entered in the search field, City property is null.
        [SearchRouterQueryKey.City]: response?.legacySearchLocation?.id,
        [SearchRouterQueryKey.Location]: response?.label,
      };
    }
  }

  const isCountryCodeVN = selectedCountry === CountryCodes.VN;

  return {
    ...location,
    [SearchRouterQueryKey.Country]: selectedCountry,
    [SearchRouterQueryKey.Location]: isCountryCodeVN
      ? defaultCityProvinceInVN
      : defaultCityProvinceAll,
    [SearchRouterQueryKey.LowestLocationlevel]: '1',
    ...(isCountryCodeVN && {
      [SearchRouterQueryKey.LocationId]: defaultLocationIdInVN,
    }),
  };
}

export function generateSearchQuery(
  keyword: string,
  locationQuery: LocationQuery,
  updatePastJobSearches: (pastSearches: SearchRouterQuery[]) => void,
  isMobile = false,
  onClose?: () => void
) {
  const query = omitBy(
    {
      [SearchRouterQueryKey.Keyword]: keyword ? keyword : null,
      ...locationQuery,
    },
    isNil
  );

  if (
    query &&
    (query[SearchRouterQueryKey.Keyword] ||
      query[SearchRouterQueryKey.Location])
  ) {
    storeSearchInLocalStorage(query, updatePastJobSearches);
  }

  if (isMobile) {
    onClose();
  }

  return {
    pathname: `/${ROUTES.opportunitiesJobsExplore}`,
    query: {
      ...query,
    },
  };
}
