import { v4 as uuidv4 } from 'uuid';

import {
  CountryCodes,
  defaultCityProvinceAll,
  defaultCityProvinceInVN,
  defaultLocationIdInVN,
} from 'src/common/enums';
import { isCountryCodeVN } from 'src/common/hooks/useCountryData';
import { buildCityProvinceLabel } from 'src/common/utils/hierarchicalLocationHelpers';
import { City } from 'src/global/models';
import { HierarchicalLocation } from 'src/modules/Profile/graphql/hierarchicalLocations';

export enum LocationType {
  Default,
  Legacy,
  Hierarchical,
}

export class DefaultLocation {
  constructor(
    public id: string,
    public CountryCode: CountryCodes,
    public name: string
  ) {}

  public static getDefaultLocationAll = (countryCode: CountryCodes) => {
    const _isCountryCodeVN = isCountryCodeVN(countryCode);
    const defaultLocation = new DefaultLocation(
      _isCountryCodeVN ? defaultLocationIdInVN : uuidv4(),
      countryCode,
      _isCountryCodeVN ? defaultCityProvinceInVN : defaultCityProvinceAll
    );
    return new NewSearchLocation(null, null, defaultLocation);
  };
}

export class NewSearchLocation {
  public type: LocationType;
  constructor(
    public legacySearchLocation?: City,
    public hierarchicalLocation?: HierarchicalLocation,
    public defaultLocation?: DefaultLocation
  ) {
    this.type = legacySearchLocation
      ? LocationType.Legacy
      : hierarchicalLocation
      ? LocationType.Hierarchical
      : LocationType.Default;
  }

  public get isHierarchicalLocation() {
    return this.type === LocationType.Hierarchical;
  }

  public get label() {
    if (this.type === LocationType.Default) {
      return this.defaultLocation.name;
    }
    return this.type === LocationType.Legacy
      ? this.legacySearchLocation.name
      : buildCityProvinceLabel(this.hierarchicalLocation);
  }

  public get name() {
    if (this.type === LocationType.Default) {
      return this.defaultLocation.name;
    }
    return this.type === LocationType.Legacy
      ? this.legacySearchLocation.name
      : this.hierarchicalLocation.name;
  }
}
