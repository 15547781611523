import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { get } from 'lodash';

import { CountryCodes } from 'src/common/enums';
import { unaccentTerm } from 'src/common/helpers';
import { validCountryForHierarchicalLocation } from 'src/common/utils/hierarchicalLocationHelpers';
import { useFetchCities } from 'src/components/Select/LocationSelect/useFetchCities';
import { City } from 'src/global/models';
import {
  DefaultLocation,
  NewSearchLocation,
} from 'src/global/models/NewSearchLocation';
import { getCitiesWithActiveJobs } from 'src/modules/Opportunities/pages/Explore/Components/SearchSection/graphql';
import {
  HierarchicalLocation,
  QuerySearchHierarchicalLocationsArgs,
  searchHierarchicalLocation,
  SearchHierarchicalLocationData,
  SuggestedHierarchicalJobLocationsArgs,
  SuggestedHierarchicalJobLocationsData,
  suggestedJobLocations,
} from 'src/modules/Profile/graphql/hierarchicalLocations';

import { getFilterSpecialRegions, getSpecialRegions } from './helpers';

type UseLocationSuggestionsQueryHookResult = {
  data: NewSearchLocation[];
  suggestionsLoading: boolean;
  searchLoading: boolean;
};

type LocationSuggestionOptions = {
  selectedLocation?: NewSearchLocation;
  noDefaultLocation?: boolean;
  shouldShowL2?: boolean;
};

export const useLocationSuggestionsQuery = (
  searchTerm: string,
  suggestionLimit: number,
  selectedCountry: CountryCodes,
  options?: LocationSuggestionOptions
): UseLocationSuggestionsQueryHookResult => {
  const selectedLocName = get(options, 'options.selectedLocation.name');
  /** this will handle the case where `formattedName` used in search
   * input is different from `name` used in the location suggestion.
   * eg: "Kab. Sleman" vs "Sleman"
   */
  const searchTermForSelectedLocation = searchTerm.includes(selectedLocName)
    ? selectedLocName
    : searchTerm;

  const hierarchicalLocationCountry =
    validCountryForHierarchicalLocation(selectedCountry);

  const suggestedLegacyCitiesResult = useQuery(getCitiesWithActiveJobs, {
    variables: {
      countryCode: selectedCountry,
      pageSize: suggestionLimit,
      currentPage: 1,
    },
    skip: hierarchicalLocationCountry,
  });

  const {
    cities: searchLegacyCities = [],
    setOptions,
    isLoading: searchLegacyLoading,
    setUseV2Cities,
  } = useFetchCities();

  useEffect(() => {
    if (searchTermForSelectedLocation.length > 0) {
      const apiOptions = {
        params: {
          limit: suggestionLimit,
          include: 'Country',
          where: {
            $and: {
              name: {
                ilike: `%${unaccentTerm(searchTermForSelectedLocation)}%`,
              },
              $CountryCode$: selectedCountry,
            },
          },
        },
      };
      setOptions(apiOptions);
      setUseV2Cities(true);
    }
  }, [
    selectedCountry,
    searchTermForSelectedLocation,
    setOptions,
    setUseV2Cities,
    suggestionLimit,
  ]);

  const mappedLegacyResults = useMemo(() => {
    if (searchTermForSelectedLocation.length > 0) {
      return searchLegacyCities.map(
        (result: City) => new NewSearchLocation(result, null)
      );
    } else {
      const suggestItems = get(
        suggestedLegacyCitiesResult.data,
        'getCitiesWithActiveJobs.cities',
        []
      )
        .map((item: any) => item.City)
        .map((result: City) => new NewSearchLocation(result, null));

      if (options?.noDefaultLocation) {
        return suggestItems;
      }
      return [
        DefaultLocation.getDefaultLocationAll(selectedCountry),
        ...suggestItems,
      ];
    }
  }, [
    options?.noDefaultLocation,
    searchLegacyCities,
    searchTermForSelectedLocation.length,
    selectedCountry,
    suggestedLegacyCitiesResult.data,
  ]);

  const suggestedHierarchicalCitiesResult = useQuery<
    SuggestedHierarchicalJobLocationsData,
    SuggestedHierarchicalJobLocationsArgs
  >(suggestedJobLocations, {
    variables: {
      countryCode: selectedCountry,
    },
    skip: !hierarchicalLocationCountry,
  });

  const searchHierarchicalLevels = (
    selectedCountry: CountryCodes
  ): number[] => {
    switch (selectedCountry) {
      case CountryCodes.VN:
        return [2];
      default:
        return [2, 3];
    }
  };

  const searchHierarchicalResult = useQuery<
    SearchHierarchicalLocationData,
    QuerySearchHierarchicalLocationsArgs
  >(searchHierarchicalLocation, {
    variables: {
      searchTerm: searchTermForSelectedLocation,
      limit: suggestionLimit,
      levels: searchHierarchicalLevels(selectedCountry),
      countryCode: selectedCountry,
    },
    skip: !hierarchicalLocationCountry,
  });

  const appendL2Locations = useCallback(
    function appendL2Locations(locations: HierarchicalLocation[]) {
      return locations
        .flatMap((result: HierarchicalLocation) => {
          const locations = [new NewSearchLocation(null, result)];
          if (selectedCountry !== CountryCodes.ID || !options?.shouldShowL2)
            return locations;
          const parent = result.parents.find((parent) => parent.level === 2);
          if (
            parent &&
            parent.formattedName
              .toLowerCase()
              .includes(searchTermForSelectedLocation.toLowerCase())
          ) {
            locations.unshift(new NewSearchLocation(null, parent));
          }
          return locations;
        })
        .filter(
          (location, index, self) =>
            index === self.findIndex((t) => t.label === location.label)
        )
        .sort(
          (a, b) =>
            (a.hierarchicalLocation?.level ?? 0) -
            (b.hierarchicalLocation?.level ?? 0)
        );
    },
    [options?.shouldShowL2, searchTermForSelectedLocation, selectedCountry]
  );

  const mappedHierarchyResults = useMemo(() => {
    const specialRegions = getSpecialRegions(selectedCountry);
    if (searchTermForSelectedLocation.length > 0) {
      const filteredSpecialRegions = getFilterSpecialRegions(
        specialRegions,
        searchTermForSelectedLocation
      );
      // TODO: restore this when searchHierarchicalLocations is migrated to V2
      const searchResults = appendL2Locations(
        get<SearchHierarchicalLocationData, 'searchHierarchicalLocations', []>(
          searchHierarchicalResult.data,
          'searchHierarchicalLocations',
          []
        )
      );
      return [...filteredSpecialRegions, ...searchResults];
    } else {
      // TODO: restore this when searchHierarchicalLocations is migrated to V2
      const suggestItems = appendL2Locations(
        get(suggestedHierarchicalCitiesResult.data, 'suggestedJobLocations', [])
      );

      if (options?.noDefaultLocation) {
        return suggestItems;
      }
      return [
        DefaultLocation.getDefaultLocationAll(selectedCountry),
        ...specialRegions,
        ...suggestItems,
      ];
    }
  }, [
    appendL2Locations,
    options?.noDefaultLocation,
    searchHierarchicalResult.data,
    searchTermForSelectedLocation,
    selectedCountry,
    suggestedHierarchicalCitiesResult.data,
  ]);

  const hierarchicalCountry =
    validCountryForHierarchicalLocation(selectedCountry);

  return {
    data: hierarchicalCountry ? mappedHierarchyResults : mappedLegacyResults,
    suggestionsLoading: hierarchicalCountry
      ? suggestedHierarchicalCitiesResult.loading
      : suggestedLegacyCitiesResult.loading,
    searchLoading: hierarchicalCountry
      ? searchHierarchicalResult.loading
      : searchLegacyLoading,
  };
};
