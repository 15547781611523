import React from 'react';
import axios from 'axios';

import { City } from 'src/global/models/City';
import { makeFullUrlApiCall } from 'src/middleware/api/helpers';
import { CallAPIOptions } from 'src/middleware/api/interfaces';

export function useFetchCities(initialOptions: CallAPIOptions = {}) {
  const signalRef = React.useRef(null);
  const [cities, setCities] = React.useState<City[] | undefined>(undefined);
  const [options, setOptions] = React.useState(initialOptions);
  const [useV2Cities, setUseV2Cities] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState(null as Error);

  React.useEffect(() => {
    let isCancelled = false;
    signalRef.current = axios.CancelToken.source();
    const signal = signalRef.current;

    (async () => {
      setError(null);
      setIsLoading(true);

      const citiesEndpoint = useV2Cities ? 'v2WithDefaultApi/cities' : 'cities';

      try {
        const result = await makeFullUrlApiCall(citiesEndpoint, {
          ...options,
          cancelToken: signal.token,
        });
        const citiesData = result.data.data;

        if (!isCancelled) {
          setCities(citiesData);
          setIsLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          setError(error);
          setIsLoading(false);
        }
      }
    })();

    return () => {
      isCancelled = true;
      signal.cancel();
    };
  }, [options, useV2Cities]);

  return { cities, isLoading, error, setOptions, setUseV2Cities };
}
